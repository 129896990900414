.card-brand {
  &.visa {
    background: url('../images/paymentCards/visa.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.mastercard {
    background: url('../images/paymentCards/mastercard.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.amex {
    background: url('../images/paymentCards/amex.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.discover {
    background: url('../images/paymentCards/discover.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.diners {
    background: url('../images/paymentCards/diners_club.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.jcb {
    background: url('../images/paymentCards/jcb.png') center center no-repeat;
    background-size: 50px 25px;
  }

  &.other {
    background: url('../images/paymentCards/default.png') center center no-repeat;
    background-size: 50px 25px;
  }
}
