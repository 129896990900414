/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;
$vin-strong-blue: #3064f6;

/* Styles */
body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  height: 500px;
  background: transparent;
}

td {
	padding: 12px;
}

h2 {
	color: $vin-blue;
}

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
td.fuel-savings-label { width: 175px; }

.input-location{
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid lightgray;
  border-radius: 0px;
}
#submit-ride,
#submit-passengers-details,
#continue-as-guest,
#submit-log-continue,
#submit-passenger-details,
#submit-register-new-account-booking-confirmed,
#register-new-account-blue,
#submit-flight{
  background-color: $vin-strong-blue;
  color: white;
}
.location-container{
  padding:1.5em 0;
}
.label-transparent {
  background-color: transparent;
}
.vertical-navbar {
  padding-top: 30px;
  margin-left: 5px;
  margin-right: 45px;
}
.input-pickup-date {
  padding-left: 15px;
  margin-top: 25px;
  color: gray;
  background-color: red;
}
.only-bottom-border{
  border: 0;
  outline: 0;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  width: 100%;
}
.date-container{
  width: 100%;
}

.ul-options {
  font-weight: bold;
}
.location-loader{
  background-color: red;

}
.submit-btn{
  padding: 0.8em 1.5em;
  margin: 1em 0;
  border-radius: 0px;
}
.strongblue{
  background-color: red;
}

.dropdown.ui.fluid.transparent.no-borders{
  border: 0;
}

.ui.fluid.transparent.only-bottom-border,.ui.fluid.transparent.only-bottom-border:focus{
  border: 0;
  outline: 0;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  width: 100%;
}

.react-datepicker-wrapper.input-pickup-date {
  padding-left: 15px;
  margin-top: 25px;
  color: gray;
  background-color: red;
}

.ui.form.ride-form,.ui.form.passenger-details-form, ui.form.payment-details-form {
  padding-top: 10px;
}

.ui.form.passenger-details-form div .col, ui.form.payment-details-form div .col {
  min-height: 62px;
  max-height: 62px;
  position: static;
}

.rideTypes {
  min-width: 134px;
  width: 45%;
}

.numberPassenger {
  min-width: 110px;
  width: 45%;
}

.ui.form.passenger-details-form div .col input, ui.form.payment-details-form div .col input {
  min-height: 50px;
  max-height: 50px;
}

#parent-phone div div, #parent-phone div input{
  max-height: 20px;
}

#parent-phone div input{
  cursor: auto;
}

#parent-phone{
  min-height: 75px;
  max-height: 75px;
  position: relative;
  padding: 25px 0;
}


.google-places-autocomplete__suggestions-container{
  position: absolute; top: 37px; left: 14px;
  width: 94%;
  right: 0; bottom: 0;
  z-index: 1000;
  color: black;
}
.no-bounce{
  background-color: red;
}
.google-places-autocomplete__suggestion {
  border: solid lightgray 0.5px;
  border-top: 0;
  font-family: Helvetica;
  background-color: white;

}
.google-places-autocomplete__suggestion {
  border-bottom: solid lightgray 0.2px;
  font-family: Helvetica;
  padding: 10px;
}
#-google-places-autocomplete-suggestion--0 {
  border-top: solid transparent;
}
.google-places-autocomplete__suggestion:hover {
  background-color: lightgray;
  cursor: default;
}
#pickUpDate,.react-datepicker__time{
  text-transform: lowercase;
}
.react-datepicker__input-container input::placeholder{
  text-transform: capitalize;
}
#passengers-details input::placeholder{
  font-weight: bold;
}
.error {
  color: #c00;
  background-color: #FFF;
  position: static;
  top: 0px;
  left: 0px;
  float: left;
  height: 15px;
}

#go-back-passengers,#register-new-account,#go-back-booking-confirmed{
 font-weight: bold;
}

.PhoneInputCountry {
  padding: 2.5px 0px;
  //padding-right: 90px;
  border: none;
  // TODO: We need to style the phone country in the expected way.
  border-bottom: transparent;
}

.ui.form div .PhoneInput input,.ui.form div .PhoneInput input:focus{
  border: none;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
}

.ui.form div.row div.col input {
  border: 0;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  margin: 10px 0;
}

#phone-number-container div div label{
  position: absolute;
  top: 30px;
  left: 10em;
  float: left;
}
#pickUpDate,.react-datepicker__time{
  text-transform: lowercase;
}
.react-datepicker__input-container input::placeholder{
  text-transform: capitalize;
}
.error {
  color: #c00;
  background-color: #FFF;
  position: static;
  top: 0px;
  left: 0px;
  float: left;
  height: 15px;
}
.selectPlaceholder {

  display: none;

}
#forgot-password-label{
  float:right;
  margin:0px -8px;
  background-color: transparent;
  cursor: pointer;
  color: rgb(60, 164, 255);
  font-weight: normal;
}
#continue-as-guest span,#register-new-account span{
  float: left;
}
#_login_form div {
  min-height: 60px;
  max-height: 60px;
  position: relative;
  padding: 5px 5px;
}
#_login_form div input {
  border-color: transparent;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  margin: 5px 0;
  min-height: 10px;
  max-height: 10px;
}

#pickup-container, #dropoff-container {
  min-width: 257px;
}

.rideFormContainer {
  padding-right: 0px !important;
}

.payMethodsContainer {
  padding-top: 20px;
}

.cardNumber, .cardHolder {
  padding-left: 0px;
  min-width: 50%;
  max-width: 100%;
}

.cardDetails {
  padding-left: 0px;
  min-width: 30%;
  max-width: 50%;
}

.rideDateContainer {
  .rideDateSelect {
    min-width: 140px;
    width: 45%;
  }
  .rideHourSelect {
    min-width: 120px;
    width: 45%;
  }
}

.formHeader {
  display: inline-block;
  width: 100%;
  padding-left: 25px !important;
  margin-bottom: 0px !important;
}

.paymentHeader {
  display: inline-block;
  width: 100%;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

.rideDropdownsContainer {
  padding-left: 0px;
}

.alert {
  width: 100%;
  height: 65px;
  z-index: 5000;
}

.detailsCardLabel {
  position: absolute;
  left: 45px;
  width: 85px !important;
}

.detailsCardTime {
  position: absolute;
  right: 0;
  min-width: 50px;
  max-width: 91px;
  float: right;
}

.ride-details-cards-container {
  min-width: 40%;
  margin-top: 3% !important;
}

.detailsCardLocation {
  margin-left: 5px;
}

.vehicle-card-container {
  min-width: 35%;
  margin-top: 50px !important;
}

.passenger-card-container {
  min-width: 35%;
  margin-top: 75px !important;
}

.serviceLevel {
  width: auto !important;
  border: solid 1px #f4f5f7 !important;
  border-radius: 0;
}

.bookingConfirmationContact {
  width: 50%;
  padding: 10px 10px;
}

.bookingConfirmationRideDetails {
  width: 50%;
  padding: 10px;
  border: solid 0.7px #f4f5f7;
}

.loginCol {
  width: 50%;
  padding: 10px;
  @media screen and (max-width: 853px) and (min-width: 768px) {
    width: 100% !important;
    padding: 10px;
  }
  @media screen and (max-width: 540px) {
    width: 100% !important;
    padding: 10px;
  }
}

.registerCol {
  width: 50%;
  padding: 10px;
  @media screen and (max-width: 853px) and (min-width: 768px) {
    width: 100% !important;
    padding: 10px;
  }
  @media screen and (max-width: 540px) {
    width: 100% !important;
    padding: 10px;
  }
}

.add-payment-card {
  margin-top: 25px !important;
  background: $vin-strong-blue !important;
}

.submit-register-account {
  margin-left: 0px !important;
  min-width: 130px;
  background: $vin-strong-blue !important;
}

.selectVehicleBtn {
  background: $vin-strong-blue !important;
}

.paymentBtnContainer {
  padding-left: 0px !important;
}

.goBackBtn {
  max-width: 106px;
}

.continueBtn {
  text-align: left !important;
}

.edit-button-margin-top {
  margin-top: 10px !important;
}

// Responsiveness.
@media screen and (max-width: 1143px) and (min-width: 853px) { // 1200+ lg-xlg , 1199-992 md, 991-768 sm, 767-480 xs, 479-240

  .rideFormContainer {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .mapFormContainer {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .bookingConfirmationContact {
    width: 85% !important;
    padding: 10px 10px;
  }

  .bookingConfirmationRideDetails {
    display: none !important;
  }

  .loginCol {
    width: 100% !important;
    padding: 10px;
  }

  .registerCol {
    width: 100% !important;
    padding: 10px;
  }

  .singInFirstName {
    min-width: 170px;
    max-width: 300px;
  }

  .singInLastName {
    min-width: 170px;
    max-width: 300px;
  }

  .ride-details-cards-container {
    min-width: 100%;
    margin-top: 7% !important;
  }

  .detailsCardLocation {
    margin-left: 0px;
  }

  .vehicle-card-container {
    margin-top: 50px !important;
  }

  .passenger-card-container {
    margin-top: 75px !important;
  }
}

@media screen and (max-width: 952px) and (min-width: 768px) {

  .rideFormContainer {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .mapFormContainer {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .rideDateContainer {
    .rideDateSelect {
      min-width: 100%;
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0;
    }
    .rideHourSelect {
      min-width: 100%;
      display: block;
      width: 100%;
      padding-right: 0;
    }
  }

  .react-datepicker-wrapper {
    width: 93%;
  }

  #pickup-container, #dropoff-container {
    min-width: 100%;
  }

  .rideTypes {
    min-width: 87.5%;
    width: 87.5%;
  }

  .numberPassenger {
    min-width: 87.5%;
    width: 87.5%;
  }

  .singInFirstName {
    min-width: 170px;
    max-width: 300px;
  }

  .singInLastName {
    min-width: 170px;
    max-width: 300px;
  }

  .bookingConfirmationContact {
    width: 85% !important;
    padding: 10px 10px;
  }

  .bookingConfirmationRideDetails {
    display: none !important;
  }

  .ride-details-cards-container {
    min-width: 100%;
    margin-top: 7% !important;
  }

  .vehicle-card-container {
    margin-top: 50px !important;
  }

  .passenger-card-container {
    margin-top: 75px !important;
  }

  .detailsCardLocation {
    position: absolute;
    left: 40px;
  }

  .rideDropdownsContainer {
    margin-left: 0 !important;
    padding-left: 0px;
  }

  .ui.form.passenger-details-form div .col {
    flex-basis: 155px !important;
  }

  .passengerNameRow {
    margin-bottom: 0px !important;
  }

  .passengerEmailRow {
    margin-top: 0px !important;
  }

  .rideLocationsInput, .pickupDateTimeRow, .rideDateContainer, .durationContainer, airportContainer {
    margin-left: 0 !important;
  }

  .passengerNameRow, .passengerEmailRow, .countryCodeRow,.phoneRow, .durationRow, .airportRow, airportDetailsRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .btnContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .rideFormContainer {
    height: auto;
    margin-bottom: 20px;
  }

  .formHeader {
    margin-bottom: 10px !important;
  }

  .paymentHeader {
    margin-bottom: 10px !important;
  }

  .rideDateContainer {
    .rideDateSelect {
      min-width: 140px;
      max-width: 250px;
      width: 45%;
    }
    .rideHourSelect {
      min-width: 120px;
      max-width: 200px;
      width: 45%;
    }
  }

  .rideTypes {
    min-width: 134px;
    max-width: 250px;
    width: 45%;
  }

  .numberPassenger {
    min-width: 110px;
    max-width: 200px;
    width: 45%;
  }

  #pickup-container, #dropoff-container {
    min-width: 257px;
  }

  .bookingConfirmationContact {
    width: 85% !important;
    padding: 10px 10px;
  }

  .bookingConfirmationRideDetails {
    display: none !important;
  }

  .ride-details-cards-container {
    min-width: 100%;
    margin-top: 7% !important;
    margin-bottom: 5% !important;
  }

  .vehicle-card-container {
    margin-top: 50px !important;
    margin-bottom: 5% !important;
  }

  .passenger-card-container {
    margin-top: 75px !important;
    margin-bottom: 5% !important;
  }

  .detailsCardLocation {
    position: absolute;
    width: auto;
    left: 40px;
  }

  .rideDropdownsContainer, .rideLocationsInput, .pickupDateTimeRow, .rideDateContainer, .durationContainer,
  .airportContainer, airlineContainer {
    margin-left: 0 !important;
  }

  .passenger-details-form {
    flex: 155px !important;
  }

  .passengerNameRow, .passengerEmailRow, .countryCodeRow,.phoneRow, .durationRow, .airportRow, .airportDetailsRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .btnContainer {
    padding-left: 0px !important;
  }

  .map {
    display: none !important;
  }
}

@media screen and (min-width: 341px) and (max-width: 575px) {
  .detailsCardTime {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 341px) and (max-width: 479px) {
  .bookingConfirmationContact {
    width: 85% !important;
    padding: 10px 10px;
  }

  .rideFormContainer {
    height: auto;
    margin-bottom: 20px;
  }

  .bookingConfirmationRideDetails {
    display: none !important;
  }

  .detailsCardTime {
    margin-left: 0 !important;
  }

  .ride-details-cards-container {
    min-width: 100%;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
  }

  .vehicle-card-container {
    margin-top: 30px !important;
    margin-bottom: 5% !important;
  }

  .passenger-card-container {
    margin-top: 75px !important;
    margin-bottom: 5% !important;
  }

  .detailsCardLabel {
    position: absolute;
    left: 35px;
    width: 85px !important;
  }

  .detailsCardTime {
    position: absolute;
    right: 15px;
    min-width: 50px;
    max-width: 91px;
    float: right;
  }

  .contentRow {
    margin-right: 0px !important;
    margin-left: 0 !important;
  }

  .rideDropdownsContainer {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .rideLocationsInput {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  #pickup-container, #dropoff-container {
    padding-left: 0px !important;
  }

  .pickupDateTimeRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .durationRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .airportRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .airportDetailsRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .durationContainer {
    padding-left: 0px !important;
  }

  .airportContainer {
    padding-left: 0px !important;
  }

  .airlineContainer {
    padding-left: 0px !important;
  }

  .pickupDateTimeLabel {
    padding-left: 0px !important;
  }

  .rideDateContainer {
    margin-left: 0px !important;
    margin-right: 0.25rem !important;
  }

  .rideDateSelect {
    padding-left: 0px !important;
  }

  .btnRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .btnContainer {
    padding-left: 0px !important;
  }

  .rideTypes {
    min-width: 134px;
    width: 45%;
    padding-left: 0px;
  }

  .formHeader {
    padding-left: 15px !important;
  }

  .ui.form.passenger-details-form div .col {
    flex-basis: 224px !important;
  }

  .passengerNameRow {
    margin-bottom: 0px !important;
  }

  .passengerEmailRow {
    margin-top: 0px !important;
  }

  .rideLocationsInput, .pickupDateTimeRow, .rideDateContainer {
    margin-left: 0 !important;
  }

  .passengerNameRow, .passengerEmailRow, .countryCodeRow,.phoneRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .btnContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .detailsCardLocation {
    position: absolute;
    width: auto;
    height: auto;
    left: 30px;
  }

  .signUpContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .map {
    display: none !important;
  }
}

@media (max-width: 340px) {
  .rideFormContainer {
    height: auto;
    margin-bottom: 20px;
  }

  .formHeader {
    margin-bottom: 10px !important;
  }

  .paymentHeader {
    margin-bottom: 10px !important;
  }

  .rideDateContainer {
    .rideDateSelect {
      min-width: 100%;
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0;
    }
    .rideHourSelect {
      min-width: 100%;
      display: block;
      width: 100%;
      padding-right: 0;
    }
  }

  .react-datepicker-wrapper {
    width: 65%;
  }

  .singInFirstName {
    min-width: 170px;
    max-width: 300px;
  }

  .singInLastName {
    min-width: 170px;
    max-width: 300px;
  }

  .bookingConfirmationContact {
    width: 85% !important;
    padding: 10px 10px;
  }

  .bookingConfirmationRideDetails {
    display: none !important;
  }

  .ride-details-cards-container {
    min-width: 100%;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
  }

  .vehicle-card-container {
    margin-top: 30px !important;
    margin-bottom: 5% !important;
  }

  .passenger-card-container {
    margin-top: 75px !important;
    margin-bottom: 5% !important;
  }

  .detailsCardTime {
    margin-left: 0 !important;
  }

  .rideDropdownsContainer, .rideLocationsInput, .pickupDateTimeRow, .rideDateContainer, .durationRow, .airportRow, .airportDetailsRow {
    margin-left: 0 !important;
  }

  .rideTypes, .numberPassenger {
    min-width: 150px !important;
    max-width: 300px;
    width: 100%;
  }

  .pickup-container, .dropoff-container {
    min-width: 150px !important;
    max-width: 300px;
    width: 100%;
  }

  .formHeader {
    padding-left: 15px !important;
  }

  .ui.form.passenger-details-form div .col {
    flex-basis: 224px !important;
  }

  .passengerNameRow {
    margin-bottom: 0px !important;
  }

  .passengerEmailRow {
    margin-top: 0px !important;
  }

  .rideLocationsInput, .pickupDateTimeRow, .rideDateContainer {
    margin-left: 0 !important;
  }

  .passengerNameRow, .passengerEmailRow, .countryCodeRow,.phoneRow {
    margin-left: 0px !important;
    margin-right: 0.25rem !important
  }

  .signUpContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .map {
    display: none !important;
  }
}

#_reset_password_container{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  .alert-container {
    position: relative;
    flex: 0 0 40%;
    margin-bottom: 20px;
  }

  .page-header {
    flex: 0 0 100%;
    justify-content: center;

    .image_banner {
      flex-basis: 30%;
    }
  }

  #_reset_password_form {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 50%;

    .form-input-container {
      margin-right: 3em;
      margin-left: 3em;
      margin-bottom: 10px;
      flex: 0 0 70%;
    }

    .form-button-container {
      display: flex;
      margin-top: 20px;
      justify-content: center;
      flex: 0 0 70%;
    }

    .form-title {
      flex: 0 0 70%;
      font-weight: bold;
      font-size: large;
      margin-left: 2em;
      margin-bottom: 15px;
    }

    justify-content: center;
  }
}
