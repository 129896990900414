$blue: #0072ae;
$light-blue: #2797D4;
$light-gray-blue: #f9fafc;
$dark-blue: #3d4a7d;
$clickable-text-blue: #428bca;
$highlight-blue: #005380;
$highlight-light-blue: #2797D4;
$highlight-dark-blue: #003654;
$primary-blue: #337ab7;
$selection-blue: #3d4a7d;
$border-gray-blue: #d8dbe4;
$green: #5cb85c;
$gray: #999;
$mid-gray: #666;
$dark-gray: #333;
$mid-light-gray: #d7d7d7;
$light-gray: #f2f2f2;
$light-green: #f1ffda;
$success-green: #2cb100;
$warning-yellow: #d88900;
$danger-red: #d80000;
$favorite-yellow: #e5df1e;
$warning-yellow-lighter: #ffe400;
$modal-header-background: #f6f7fb;
$light-header-background: #fffefd;
$deep-blue: #2f64f6;
$light-purple: #6e95fb;

$gold: #d88900;
