@import 'colors';

.dr-credit-card-list {

  .credit-card-list {

    list-style-type: none;

    padding: 0;
    margin-bottom: 15px;

    li {
      min-height: 60px;

      margin-bottom: 3px;

      .no-credit-cards, .loading-credit-cards {
        background: #e9e9e9;
        color: $mid-gray;
        text-align: center;
        font-size: 16px;

        height: 60px;
        padding-top: 20px;
      }

      .card-info-type-badge {
        background: $light-gray-blue;
        font-size: 12px;
        color: gray;
        padding: 3px;
        text-transform: uppercase;
        border-radius: 5px;
        margin-right: 5px;
        border: solid thin $border-gray-blue;
      }

      .card-info-brand {
        font-size: 14px;
        font-weight: 500;

        .fa-circle {
          font-size: 5px;
          position: relative;
          bottom: 3px;

        }
      }

      &.half-width {

        .card-info-brand, .card-info-expiration, .card-info-label {
          display: block;
          width: 100%;
        }

        .card-info-brand, .card-info-expiration  {
          margin-bottom: 3px;
        }
      }

      &.primary-card {

        .credit-card-table {
          background: #E5FBE5;
          color: #2cb100;
        }

        .set-primary {
          cursor: default !important;
          opacity: 1 !important;
        }
      }

      &.problem-with-card {

        .credit-card-table {
          background: #ffd8d8;
          color: #B20000;
        }


        .set-primary {
          cursor: default !important;

          color: #000 !important;
          opacity: 0.5 !important;
        }
      }

      .credit-card-table {
        display: table;

        background: white;

        width: 100%;
        padding: 0 15px;
        min-height: 60px;
        border: solid thin $border-gray-blue;

        .card-image, .card-info, .card-actions {
          vertical-align: middle;
        }

        .card-image {
          display: table-cell;

          width: 5%;

          div {
            height: 25px;
            width: 50px;
          }
        }

        .card-info {
          display: table-cell;

          width: 80%;
          padding: 5px 0 5px 15px;
          font-size: 12px;
        }

        @media (max-width: 1200px) {
          .card-info {

            .card-info-brand, .card-info-expiration {
              display: block;
              width: 100%;
            }

            .card-info-brand {
              margin-bottom: 3px;
            }
          }
        }

        .card-actions {
          display: table-cell;

          width: 15%;
          padding-bottom: 1px;

          .card-actions-table {
            display: table;
            float: right;
          }

          .card-tags, .action-primary, .action-delete {
            display: table-cell;
          }

          .card-tags {
            text-align: right;
            margin-right: 14px;
            font-size: 11px;
            font-weight: 500;

            > * {
              position: relative;
              bottom: 3px;
            }
            .primary-tag {
              color: $success-green;
            }

            .declined-tag, .expired-tag {
              color: #c12e2a;
            }

            .primary-tag, .declined-tag, .expired-tag {
              text-transform: uppercase;
            }
          }

          .row-tags {
            min-height: 17px;
          }

          .row-actions {
            float: right;
          }

          .card-tags, .action-primary {
            padding-top: 5px;
          }

          .action-primary {
            padding: 5px 10px 0 0;

            margin-right: 0px;

            font-size: 16px;

            .set-primary {
              cursor: pointer;

              color: $success-green;
              opacity: 0.5;

              &:hover {
                opacity: 0.7;
              }
            }
          }

          .action-update {
            padding: 0;
            font-size: 20px;

            span {
              cursor: pointer;
              color: $clickable-text-blue;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}
